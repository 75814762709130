export const ADHOC = "Adhoc Payments";

export const PAY_FOUND = "Payments Found";
export const PAGINATION = { COMMON__LIMIT: 500 };

export const drivers = {
  DELIVERY_STARTED: "Delivery Started",
};

export const transactionStatusList = [
  { title: "Pending", value: "pending" },
  { title: "Rejected", value: 'rejected' },
  { title: "Processed", value: "processed" },
  { title: "Approved", value: "approved" },
  { title: "Paid", value: "paid" },
  { title: "Cancelled", value: "cancelled" },
  { title: "Failed", value: "failed" },
];

export const WeekDates = () => {
  const selectedDate = new Date();
  const dayOfWeek = selectedDate.getDay();
  const thisWeekStartDate = new Date(selectedDate);
  thisWeekStartDate.setDate(selectedDate.getDate() - dayOfWeek + 1); // Addition 1 to select date from Monday
  const thisWeekEndDate = new Date(thisWeekStartDate);
  thisWeekEndDate.setDate(thisWeekStartDate.getDate() + 6);

  return { thisWeekStartDate, thisWeekEndDate };
}