import React from "react";
import { CircularProgress, Modal } from "@mui/material";
import moment from "moment";

import { actions } from "../../../../redux/store/store";
import { timeTransform_hm, timeTransform_h } from "../../../../util/time";
import "./style.scss";
import "../../../shared/customModal/style.scss";
import { useGetTransactionsQuery } from "../../../../api/reports";
import { maskingInfo } from "../../helper";
import { convertToCapitalize } from "../../../../constants/extras/convertToCapitalize";

const DetailsLayout = ({ children }) => {
  return <main className="main__detail__container">{children}</main>;
};

const Details = (props) => {
  const { name, description, timeTable } = props.detail;
  return (
    <section className="detail__container">
      <div className="detail__name__container">
        <span className="details_name">{name}</span>
      </div>
      <div className="detail__description__container">
        {!timeTable && (
          <span className="details_description">{description}</span>
        )}
        {timeTable &&
          description.map((time, idx) => {
            return <p key={idx}>{time}</p>;
          })}
      </div>
    </section>
  );
};

const TransactionsModal = (props) => {
  let data = JSON.parse(props.id);

  const { data: detailObj, isFetching } = useGetTransactionsQuery(
    { transactionId: data.transactionId },
    {
      refetchOnMountOrArgChange: true,
      skip: !data.transactionId,
    }
  );

  let detail = detailObj ? detailObj : null;

  const handleShiftTimeTable = (time) => {
    let arr = [];
    for (let i = 0; i < time.length; i++) {
      if (time[i] === 0) {
        arr.push("Early Morning(4AM - 8AM)");
      }
      if (time[i] === 1) {
        arr.push("Morning(8AM - 12PM)");
      }
      if (time[i] === 2) {
        arr.push("Afternoon(12PM - 4PM)");
      }
      if (time[i] === 3) {
        arr.push("Evening(4PM - 8PM)");
      }
      if (time[i] === 4) {
        arr.push("Late Evening(8PM - 12AM)");
      }
    }
    return arr;
  };

  const timeOutputFormat = (str) => {
    const temp = str.split(/[h] |m/);
    return (
      <div
        className="time-output"
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <span className="time-output__item">
          {temp[0]}
          <span className="time-output__sign">h</span>
        </span>

        <span className="time-output__item">
          {temp[1]}
          <span className="time-output__sign">m</span>
        </span>
      </div>
    );
  };

  const detailsArray =
    detail && (detail.metro || detail.price)
      ? [
          { name: "Metro", description: detail.metro ? detail.metro : "-" },
          {
            name: "Hub",
            description:
              detail.from && detail.from.name ? detail.from.name : "-",
          },
          {
            name: "Route Date",
            description: detail.dayDeliveryStart
              ? moment(detail.dayDeliveryStart).utc().format("MM-DD-yyyy")
              : "-",
          },
          {
            name: "Start Time",
            description: detail.startTime
              ? timeOutputFormat(timeTransform_hm.toDisplay(detail.startTime))
              : "-",
          },
          {
            name: "Estimated Shift Duration",
            description: `${timeTransform_h.toDisplay(
              detail.estimatedShiftTimeFrom
            )}h - ${timeTransform_h.toDisplay(detail.estimatedShiftTimeTo)}h`,
          },
          {
            name: "Estimated Shift Pay($)",
            description: `${
              detail.estimatedShiftPayFrom &&
              typeof detail.estimatedShiftPayFrom === "number"
                ? "$" + detail.estimatedShiftPayFrom.toFixed(2)
                : ""
            } - ${
              detail.estimatedShiftPayTo &&
              typeof detail.estimatedShiftPayTo === "number"
                ? "$" + detail.estimatedShiftPayTo.toFixed(2)
                : ""
            }`,
          },
          {
            name: "Finalized Payment Amount",
            description: detail.price
              ? "$".concat(detail.price.toFixed(2))
              : "-",
          },
          { name: "Note", description: detail.note ? detail.note : "-" },
          {
            name: "Status",
            // description: detail.status
            //   ? detail.status === "approved"
            //     ? "Waiting for approval"
            //     : convertToCapitalize(detail.status)
            //   : "-",
            description: detail.status
              ? convertToCapitalize(detail.status)
              : "-",
          },
          {
            timeTable: true,
            name: "Shift Window",
            description: detail.availableTime
              ? handleShiftTimeTable(detail.availableTime)
              : "-",
          },
        ]
      : detail &&
        (detail.payeeName || detail.payee || detail.amount) && [
          {
            name: "Date",
            description: detail.date ? moment(detail.date).format("LLL") : "-",
          },
          {
            name: "Payee Name",
            description: detail.payeeName ? detail.payeeName : "-",
          },
          {
            name: "Amount",
            description: detail.amount
              ? "$".concat(detail.amount.toFixed(2))
              : "-",
          },
          {
            name: "Account Detail",
            description:
              detail.accountInfo && detail.accountInfo.depositAccountNumber
                ? maskingInfo(detail.accountInfo.depositAccountNumber)
                : "-",
          },
          {
            name: "Note",
            description: detail.note ? detail.note : "-",
          },
        ];

  const title = `Transaction ${
    data.transactionId ? "#".concat(data.transactionId) : ""
  } Details`;

  return (
    <Modal
      open
      onClose={() => actions.modal.closeTransaction()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        margin: detail && !(detail.metro || detail.status) ? "8% 0" : "",
      }}
    >
      <div
        className={`modal ${isFetching ? "when-loading" : "after-loading"}`}
        style={{ maxWidth: "55.875rem" }}
      >
        <div
          className="modal__header transaction__id__header"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <h6 className="modal__title" style={{ color: "#FFFFFF" }}>
            {title}
          </h6>
        </div>
        <div className="modal__body">
          {isFetching ? (
            <div className="loading-wrapper">
              <CircularProgress className="loading-progress" />
            </div>
          ) : detail &&
            (detail?.amount ||
              detail?.metro ||
              detail?.payeeName ||
              detail?.price) ? (
            <>
              <DetailsLayout>
                {detailsArray &&
                  detailsArray.length &&
                  detailsArray.map((detail) => {
                    return (
                      !detail.sublabel && (
                        <Details detail={detail} key={detail.name} />
                      )
                    );
                  })}
              </DetailsLayout>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                height: "25rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h6>No Details Found!!!</h6>
            </div>
          )}
        </div>

        <div className="modal__footer">
          <button
            className="btn btn_accent"
            disabled={isFetching}
            onClick={() => actions.modal.closeTransaction()}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionsModal;
