import React, { useCallback } from "react";
import { CircularProgress, Modal } from "@mui/material";

import { actions } from "../../../../redux/store/store";
import { useGetOrderLogsQuery } from "../../../../api/logs";
import Table from "../../Table/index";
import { handleStatusesBadge } from "../../../../constants/extras/statusesBadge";
import {
  convertToCapitalize,
  removeDash,
} from "../../../../constants/extras/convertToCapitalize";
import moment from "moment";
import { cancelType } from "../../../../constants/extras/rewording";

const OrderLogsDetailsModal = (props) => {
  const { id } = props;
  const title = id
    ? `Order ${id ? "#".concat(id).concat(" logs") : ""}`
    : "New Metro";

  const { data: orderLogs, isFetching } = useGetOrderLogsQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });

  const onCancel = useCallback(() => {
    actions.modal.closeOrderLogs();
  }, []);

  const columns = [
    {
      name: "timestamp",
      label: "Date",
      sortable: true,
      className: "w-25 py-2",
      renderer: (item) => moment(item?.timestamp).format("MM-DD-YYYY hh:mm A"),
    },
    {
      name: "admin-username",
      label: "Username",
      className: "w-25",
      sortable: true,
      renderer: (item) =>
        item && item?.admin?.username ? item?.admin?.username : "-",
    },
    {
      name: "admin-firstName",
      label: "Description",
      sortable: true,
      className: "align-center w-30",
      renderer: (item) => {
        return (
          <>
            {item &&
              item?.driver &&
              (item?.driver?.firstName || item?.driver?.lastName) && (
                <div>
                  <dl className="term term_inline">
                    <dt className="term__title">Driver:</dt>
                    <dd className="term__value">
                      {item &&
                        (item?.driver && item?.driver?.firstName
                          ? item?.driver?.firstName
                          : item && item?.rejectedReason
                            ? ""
                            : "-"
                        ).concat(
                          item?.driver && item?.driver?.lastName
                            ? " ".concat(item?.driver?.lastName)
                            : ""
                        )}
                    </dd>
                  </dl>
                </div>
              )}

            {item?.transactionStatus && (
              <div>
                <span>
                <strong>Status: </strong>{" "}
                {item?.transactionStatus}
                </span>
              </div>
            )}
            {(item?.rejectedReason || item?.reason || item?.transactionFailedReason) && (
              <div>
                <span>
                  {item?.rejectedReason || item?.reason || item?.transactionFailedReason ? (
                    <>
                      <strong>Reason: </strong>{" "}
                      {item?.payload?.cancelType && cancelType(item?.payload?.cancelType) + " - "}
                      {item?.rejectedReason || item?.reason || item?.transactionFailedReason}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            )}
            {(item?.activity && !(item?.rejectedReason || item?.reason || item?.transactionFailedReason) && !(item?.driver?.firstName || item?.driver?.lastName)) && (
              <div>
                <span>
                  {item?.activity ? (
                    <>
                      {item?.activity}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      name: "status",
      label: "Status",
      className: "table__td_centered w-20",
      sortable: true,
      renderer: (item) => (
        <span style={handleStatusesBadge(item && item?.status)}>
          {/* {removeDash(
            item?.status === "approved"
              ? "Waiting for approval"
              : convertToCapitalize(item?.status)
          )} */}
          {removeDash(convertToCapitalize(item?.status))}
        </span>
      ),
    },
  ];

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className={`modal ${!isFetching ? "after-loading" : "when-loading"}`}
        style={{ maxWidth: "75.875rem" }}
      >
        <div className="modal__header">
          <h2 className="modal__title">{title}</h2>
        </div>
        <div className="modal__body">
          {isFetching ? (
            <div
              className="inner__scroll__view__container"
              style={{ height: "8rem" }}
            >
              <CircularProgress />
            </div>
          ) : (
            <React.Fragment>
              {orderLogs &&
                orderLogs?.status === 200 &&
                !orderLogs?.error &&
                orderLogs?.result?.length > 0 ? (
                <div className="card" style={{ border: "1px solid #DADADA" }}>
                  <div
                    style={{
                      height: "30vh",
                      overflow: "auto",
                    }}
                  >
                    <Table
                      columns={columns}
                      items={orderLogs && orderLogs?.result}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="inner__scroll__view__container"
                  style={{ height: "10vh" }}
                >
                  No result found
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="modal__footer">
          <button
            className="btn btn_accent"
            onClick={() => actions.modal.closeOrderLogs()}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OrderLogsDetailsModal;
