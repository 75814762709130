import React, { useState, useEffect } from "react";
import { MdRefresh } from "react-icons/md";
import { Select, MenuItem, CircularProgress } from "@mui/material";
import moment from "moment";
import cn from "classnames";
import { IoIosAddCircle } from "react-icons/io";

import Table from "../../../components/shared/Table";
import {
  useGetAdhocPaymentQuery,
  useGetTransactionStatusMutation,
} from "../../../api/drivers";
import { actions } from "../../../redux/store/store";
import { useSelector } from "react-redux";
import "../../../styles/_data.scss";
import "./style.scss";

import { maskingInfo } from "../../../components/shared/helper/index";
import { convertToCapitalize } from "../../../constants/extras/convertToCapitalize";
import { handleStatusesBadge } from "../../../constants/extras/statusesBadge";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import {
  hoverAndFocusedStyle,
  ordersStatusSelect,
} from "../../../components/shared/orderHelper/styles";
import { ADHOC } from "../../../constants/variables";
import { checkArrForMapping } from "../../../constants/validaitonSchema/validation.ts";
import { handleSearch } from "../../../constants/searchQuery";
import { Clear, Refresh } from "@mui/icons-material";
import { IoSearchSharp } from "react-icons/io5";

export default function AdhocPayment() {
  const [dayFilter, setDayFilter] = useState(1);
  const { data: adhocPayments, isFetching, refetch } = useGetAdhocPaymentQuery(
    { day: dayFilter },
    { refetchOnMountOrArgChange: true }
  );

  const [referesh, refreshReq] = useGetTransactionStatusMutation();

  const [filteredAdhocPayment, setFilteredAdhocPayment] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [transactionStatusFilter, setTransactionStatusFilter] = useState("all");
  const [isLoading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    setLoading(isFetching);
    if (!adhocPayments) return;
    setFilteredAdhocPayment(adhocPayments);
    if (searchQuery) {
      handleSearchingAdhocPayment()
    }

    if (transactionStatusFilter !== "all") {
      setFilteredAdhocPayment((filteredAdhocPayment) =>
        filteredAdhocPayment.filter(({ transactionStatus }) => {
          return (
            // (transactionStatus === "approved"
            //   ? "Waiting for approval"
            //   : transactionStatus) === transactionStatusFilter
            transactionStatus === transactionStatusFilter
          );
        })
      );
    }
  }, [adhocPayments, transactionStatusFilter, isFetching]);


  const handleSearchingAdhocPayment = (value) => {
    let filtered = adhocPayments.filter(({ payeeName, transactionId, note }) => {
      const srch = value === 'CLEAR' ? '' : searchQuery;
      return (
        (payeeName && handleSearch(payeeName, srch)) ||
        (transactionId && handleSearch(transactionId, srch)) ||
        (note && handleSearch(note, srch))
      );
    })

    setFilteredAdhocPayment((prev) => [...filtered]);

  }


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchingAdhocPayment();
    }
  };

  let badgeStyle = {
    backgroundColor: "#4195f5",
    color: "white",
    borderRadius: "3px",
    padding: "3px 3px",
    textAlign: "center",
    float: "right",
    width: "auto",
    fontSize: "70%",
  };

  const columns = [
    {
      name: "date",
      label: "Date",
      className: "w-10",
      sortable: true,
      renderer: (item) =>
        item.date && moment(item.date).format("MM-DD-YYYY hh:mm A"),
    },
    {
      name: "payeeName",
      label: "Payee Name",
      sortable: true,
      className: "w-10",
    },
    {
      name: "amount",
      label: "Amount",
      sortable: true,
      renderer: (item) =>
        item && item.amount && typeof item.amount === "number"
          ? "$".concat(item.amount.toFixed(2))
          : "-",
    },
    {
      name: "transactionId",
      label: "Transaction Id",
      sortable: true,
      renderer: (item) => {
        return (
          item.transactionId &&
          (
            <div>
              {`${item.transactionId}`}{" "}
              {item.processingChannel && <span style={badgeStyle}>{`${item.processingChannel}`}</span>}
            </div>
          )
        );
      },
    },
    {
      name: "transactionStatus",
      label: "Last Updated Status",
      sortable: true,
      className: "table__td_centered p-0 px-2 w-7",
      renderer: (item) => (
        <>
          <span style={handleStatusesBadge(item.transactionStatus)}>
            {/* {`${item &&
              item.transactionStatus &&
              item.transactionStatus === "approved"
              ? "Waiting for approval"
              : convertToCapitalize(item?.transactionStatus)
              }`} */}
            {`${item &&
              item.transactionStatus &&
              convertToCapitalize(item?.transactionStatus)
              }`}
          </span>
        </>
      ),
    },
    {
      name: "description",
      label: "Remark",
      sortable: true,
      // renderer: (item) =>
      //   item.accountInfo && maskingInfo(item.accountInfo.depositAccountNumber),
    },
    { name: "note", label: "Notes", sortable: true },
    {
      name: "approvedBy-firstName",
      label: "Approved By",
      sortable: true,
      renderer: ({ approvedBy, approvedAt: appAt }) => {
        const { username } = approvedBy || {};
        return (
          <>
            {approvedBy && username && (
              <p style={{ margin: "0" }} className="approved__tag__name">
                {`${username?.split("@")[0]}`}
              </p>
            )}
            <span className="approved__date">
              {`${appAt && `(${moment(appAt).format("MM-DD-YYYY hh:mm A")})`}`}
            </span>
          </>
        );
      },
    },
    {
      name: "paidBy-firstName",
      label: "Paid By",
      sortable: true,
      renderer: ({ paidBy, paidAt }) => {
        const { username } = paidBy || {};
        return (
          <>
            {paidBy && username && (
              <p style={{ margin: "0" }} className="approved__tag__name">
                {`${username?.split("@")[0]}`}
              </p>
            )}
            <span className="approved__date">
              {`${paidAt && `(${moment(paidAt).format("MM-DD-YYYY hh:mm A")})`
                }`}
            </span>
          </>
        );
      },
    },
    {
      name: "refresh",
      className: "table__td_have-btn align-center",
      renderer: (item) => (
        <button
          className={cn("btn btn_accent customized-details-btn", {
            btn_disabled: refreshReq.isLoading,
          })}
          onClick={() => referesh(item._id)}
        >
          {refreshReq.originalArgs === item._id && refreshReq.isLoading && (
            <CircularProgress className="btn__progress" />
          )}
          Refresh <MdRefresh className="btn__icon btn__icon_right" />
        </button>
      ),
    },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="card">
              <HeaderTextLayout
                headerText={ADHOC}
                className="col-md-2"
                data={
                  checkArrForMapping(filteredAdhocPayment)
                    ? `${filteredAdhocPayment.length} Payees Found`
                    : ""
                }
                dataLength={
                  Array.isArray(filteredAdhocPayment)
                    ? filteredAdhocPayment.length
                    : "0"
                }
              >
                <div className="col-md-10 col-sm-12 filter">
                  <div className="filter__inner p-0 justify-content-end " style={{ gap: "0.5rem" }}>
                    <div className="filter__ctrls-holder filter__input-holder d-flex justify-content-end">
                      <div className="searchbar__container pr-2">

                        <input
                          className="pr-2 form__input customized__input"
                          value={searchQuery}
                          onChange={(e) => {
                            setSearchQuery(e.target.value);
                            if (e.target.value === "")
                              handleSearchingAdhocPayment("CLEAR");
                          }}
                          placeholder="Quick Search"
                          onKeyDown={handleKeyDown}
                        />
                        {searchQuery?.length > 0 && (
                          <Clear
                            className="clear__btn filter__search-icon"
                            onClick={() => {
                              setSearchQuery("");
                              handleSearchingAdhocPayment("CLEAR");
                            }}
                            style={{
                              right: "52px",
                              top: "12px",
                            }}
                          />
                        )}
                        <button
                          disabled={searchQuery?.length <= 0}
                          className="search__button"
                          onClick={() => handleSearchingAdhocPayment()}
                        >
                          <IoSearchSharp />
                        </button>
                      </div>
                    </div>
                    <div className="pl-1 d-flex align-items-center">
                      <Select
                        sx={{
                          ...hoverAndFocusedStyle,
                          ...ordersStatusSelect,
                          width: "150px",
                        }}
                        value={dayFilter}
                        onChange={(e) => setDayFilter(e.target.value)}
                      >
                        <MenuItem value={"1"}>Last 24 hours</MenuItem>
                        <MenuItem value={"2"}>Last 48 hours</MenuItem>
                        <MenuItem value={"7"}>Last 7 days</MenuItem>
                        <MenuItem value={"30"}>Last 1 month</MenuItem>
                        <MenuItem value={"90"}>Last 3 month</MenuItem>
                        <MenuItem value={"0"}>All</MenuItem>
                      </Select>
                    </div>
                    <div className="pl-3 d-flex align-items-center">
                      <Select
                        sx={{
                          ...hoverAndFocusedStyle,
                          ...ordersStatusSelect,
                          width: "150px",
                        }}
                        value={transactionStatusFilter}
                        onChange={(e) =>
                          setTransactionStatusFilter(e.target.value)
                        }
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"processed"}>Processed</MenuItem>
                        <MenuItem value={"pending"}>Pending</MenuItem>
                        <MenuItem value={"failed"}>Failed</MenuItem>
                        <MenuItem value={"rejected"}>Rejected</MenuItem>
                        {/* <MenuItem value={"Waiting for approval"}>
                          Waiting for approval
                        </MenuItem> */}
                        <MenuItem value={"approved"}>
                          Approved
                        </MenuItem>
                      </Select>
                    </div>

                    {currentUser && currentUser?.role === "root" && (
                      <div className="d-flex align-items-center">
                        <button
                          style={{
                            background: "white",
                            borderColor: "#e6e6e6",
                            padding: "0 20px"
                          }}
                          className="btn btn__accent mr-0 ml-3"
                          onClick={() => actions.modal.openAdhocpayment()}
                        >
                          <IoIosAddCircle style={{ fontSize: "22px" }} />
                          {ADHOC}
                        </button>
                      </div>
                    )}

                    <button className="refresh_btn ml-3" onClick={() => refetch()}>
                      <Refresh className="refresh_icon" />
                    </button>
                  </div>
                </div>
              </HeaderTextLayout>
              {isLoading ? (
                <div className="loading-holder">
                  <CircularProgress className="loading-progress" />
                </div>
              ) : (
                <React.Fragment>
                  {filteredAdhocPayment && filteredAdhocPayment.length ? (
                    <div className="table-wrapper normal_table">
                      <Table
                        columns={columns}
                        items={filteredAdhocPayment}
                        className="table_orders"
                        width="100%"
                      />
                    </div>
                  ) : (
                    <div className="no_results">No Results</div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
