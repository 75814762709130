import { actions } from "../../redux/store/store";

export const checkForError = (error) => {
  const message = error
    ? error?.message ||
      error?.error ||
      (error?.data && error?.data?.message) ||
      error?.data || error
    : null;
  if (!error) return;
  actions.notifications.add({
    message: message || "Something went wrong!",
    status: "error",
  });
};

export const sendErrorMessage = (msg) => {
  if (msg && msg?.length > 0) {
    actions.notifications.add({
      message: msg,
      status: "error",
    });
  }
};
