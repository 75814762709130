export const handlePaymentStatus = (status, price, isRequestedPayments, isWeeklyPayments) => {
    let pStatus = ''

    switch (status) {
      case "complete":
        if(price) {
          pStatus = 'Pending Approval'
        }
        break;

      // case "approved":
      //   pStatus = 'Pending Weekly Payment'
      //   break;

      // case "paid":
      //   if(isRequestedPayments){
      //     pStatus = 'Daily'
      //   } else if(isWeeklyPayments){
      //     pStatus = 'Weekly(Driver)'
      //   } else {
      //     pStatus = 'Weekly(Default)'
      //   }
      //   break;
    
      default:
        pStatus = status
        break;
    }

    return pStatus
}