import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { MenuItem, CircularProgress, Menu, Fade } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IoIosAddCircle } from "react-icons/io";
import { IoChevronForward, IoSearchSharp } from "react-icons/io5";
import { MdPayment, MdCancel } from "react-icons/md";
import { ClearAll, Refresh } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { CSVLink } from "react-csv";

import "../../../styles/_data.scss";
import "./style.scss";
import "../../../components/DirectPayment/style.css";
import {
  getOrdersList,
  getOrdersCount,
  useRemoveOrderMutation,
  useApproveOrderPaymentMutation,
} from "../../../api/orders";
import MassPaymentsModal from "../../../components/shared/customModal/massPayments";
import { getRoute } from "../../../util/time";
import { actions } from "../../../redux/store/store";
import ConfirmDialogModal from "../../../components/shared/customModal/confirmDialog";
import {
  convertToCapitalize,
  removeDash,
} from "../../../constants/extras/convertToCapitalize";
import HeaderTextLayout from "../../../components/shared/HeaderTextLayout";
import { handleBackgroundCheck, handleStatusesBadge } from "../../../constants/extras/statusesBadge";
import { headers, shiftsData } from "../../../constants/csv/csvShifts";
import { handleEstimatedShiftPay, handleEstimatedShiftTime } from "../../../constants/extras/handleShiftPayAndTime";
import {
  handleOrderClick,
  handleSelectAll,
  handleSelectAllClick,
} from "../../../constants/checkBoxClick";
import { sendSuccessMessage } from "../../../constants/extras/sendSuccessMsg";
import { checkForError } from "../../../constants/extras/errorHandlers";
import { PAGINATION } from "../../../constants/variables/index";
import { checkArrForMapping } from "../../../constants/validaitonSchema/validation.ts";
import {
  DateCreater,
  currentDtArr,
  DATE,
  createDate,
} from "../../../constants/variables/dates";
import CardLayout from "../../../components/layouts/CardLayout";
import RejectShift from "../../../components/shared/orderHelper/RejectShift";
import { API_SUCCESS } from "../../../constants/variables/successMessage";
import Table from "../../../components/shared/Table";
import InfiniteScroll from "react-infinite-scroll-component";
import { cancelType, rewordingOrderStatus } from "../../../constants/extras/rewording";
import { exportMenu, shiftRouteSelectOption, statusList } from "../../../constants/variables/arrays";
import { handleCurrency } from "../../../constants/extras/handleNumbers";
import cn from "classnames";
import { dataExport, xlsxDataExport } from "../../../constants/extras/dataExport";
import CustomShiftDrawer from "../../../components/shared/customDrawer/shift";
import filterLogo from "../../../images/filter-icon.svg"
import { useGetSavedFilterDataQuery } from "../../../api/savedFilter";
import ReactSelect from "react-select";
import { shiftReportStatus, shiftTypeOption, hideShiftColumn } from "../../../constants/menuLists";
import { autocomplete } from "../../../components/shared/orderHelper/styles";
import { MdPlace } from "react-icons/md";
import StoreIcon from "@mui/icons-material/Store";
import HubSelect from "../../../components/shared/hubSelect";
import MetroSelect from "../../../components/shared/metroSelect";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { DateRangePicker } from "react-date-range";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MultiSelect from "../../../constants/multiSelectDropdown";
import { ReactComponent as DoneLogo } from "../../../images/logo-done.svg"
import { orderReactSelectStyle } from "../../../constants/multiSelectDropdown/multiSelectStyle";
import { handlePaymentStatus } from "../../../constants/extras/handlePaymentStatus.js";

const { CANCELLED_MULTIPLE, CANCELLED_SINGLE } = API_SUCCESS.SHIFT;
const { COMMON__LIMIT: limit } = PAGINATION;


export default function Orders() {
  const loc = useLocation();
  const { pathname } = loc;
  const [showMassPaymentsModal, setShowMassPaymentsModal] = useState(false);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState(createDate(new Date(), DATE.Y_M_D));
  const [toDate, setToDate] = useState(createDate(new Date(), DATE.Y_M_D));
  const [state, setState] = useState(currentDtArr);
  const [disable, setDisable] = useState(false);

  const wrapperRef = useRef(null);
  const currentUser = useSelector((state) => state.auth.currentUser)
  const { data: getsavedData } = useGetSavedFilterDataQuery({ id: currentUser?._id, screen: "shift" }, {
    refetchOnMountOrArgChange: true,
    skip: currentUser?._id === undefined ? true : false
  })

  const metro = useSelector((state) => state?.metro?.value);
  const metros = useSelector((state) => state?.metro?.metros);

  const [remove, removeReq] = useRemoveOrderMutation();
  const isRefetch = useSelector((state) => state.refetch.value);

  const [filteredOrders, setFilteredOrders] = useState([]);

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [payedOrdersChecked, setPayedOrdersChecked] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [search, setSearch] = useState("");
  const [activeItem, setActiveItem] = useState();
  const [deleteShiftTemplate, setDeleteShiftTemplate] = useState([false, ""]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [arrOfStatusesCount, setStatusArr] = useState([]);
  const [reasonForScore, setReasonForScore] = useState("excess_drivers");
  const [isRejectingShift, setIsRejectingShift] = useState(false);
  const [isRejectShift, setIsRejectShift] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [csvData, setCsvData] = useState(false);
  const [isTotal] = useState(true)
  const [total, setTotal] = useState("")
  const csvInstance = useRef();
  const [openDrawer, setOpenDrawer] = useState(false)
  const [isApply, setIsApply] = useState(false)
  // const [metros, setMetros] = useState([])
  const [hubs, setHubs] = useState([])
  const [statusFilter, setStatusFilter] = useState([])
  const [saveData, setSaveData] = useState("")
  const [getID, setGetID] = useState("")
  const [prevHubs, setPrevHubs] = useState([])
  const [prevStatusFilter, setPrevStatusFilter] = useState([])
  const [status, setStatus] = useState([]);
  const [shiftTypeState, setShiftTypeState] = useState([]);
  const [rejectStatus, setRejectStatus] = useState(false)
  const [showCancel, setShowCancel] = useState([])
  const defaultHideShiftColumn = ['Starting Delivery Station', 'Estimated Shift Time(in hours)', 'Estimated Shift Pay']
  const [hideColumn, setHideColumn] = useState(defaultHideShiftColumn)
  const [showColumn, setShowColumn] = useState(hideShiftColumn)
  const [columndata, setColumnData] = useState([])

  const [date, setDate] = useState(currentDtArr)
  const [prevFilterDate, setPrevFilterDate] = useState(date)
  const [dateStart, setDateStart] = useState(createDate(new Date(), DATE.Y_M_D));
  const [dateEnd, setDateEnd] = useState(createDate(new Date(), DATE.Y_M_D));
  const [showDate, setShowDate] = useState(false)
  const [isShiftRejectReason, setIsShiftRejectReason] = useState(false)
  const [isRejectReason, setIsRejectReason] = useState(false)
  const [isArriveHub, setIsArriveHub] = useState("")
  const [isArriveToggle, setIsArriveToggle] = useState("")
  const modalOrderOpen = useSelector((state) => state.modal.order.open);
  const { role } = useSelector((state) => state.auth.currentUser || {});
  const [prevDate, setPrevDate] = useState(state);
  const { M_D_Y } = DATE;
  const [hub, setHub] = useState({ name: "", id: "" })
  const data = [...hideShiftColumn]
  const [showConfirmApprove, setShowConfirmApprove] = useState(false);
  const [changeStatusForApproveOrderPayments, { isLoading: isApproveOrderLoading }] = useApproveOrderPaymentMutation();
  const [InterestedDriverModalData, setInterestedDriverModalData] = useState()
  const [selectedRouteMethod, setSelectedRouteMethod] = useState("")

  const dateRange = `${dateStart
    ? `${createDate(dateStart, M_D_Y)} TO ${createDate(dateEnd, M_D_Y)}`
    : "Select Custom Date Range"
    }`;

  const isShowingDtRangePicker = showDateRangePicker ? (
    <KeyboardArrowUpIcon />
  ) : (
    <KeyboardArrowDownIcon />
  );

  const [exceptionsPaydata, setExceptionsPaydata] = useState([]);
  const [exceptionsApprovedata, setExceptionsApprovedata] = useState([]);

  const propsobj = {
    metros,
    setMetros: (args) => actions.metro.selectMetros(args),
    hubs,
    setHubs,
    statusFilter,
    setStatusFilter,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    isRejectShift,
    setIsRejectShift,
    isRejectReason,
    setIsRejectReason,
    isArriveHub,
    setIsArriveHub,
    isArriveToggle,
    setIsArriveToggle,
    showDateRangePicker,
    setShowDateRangePicker,
    setState,
    state,
    columndata,
    setColumnData,
    hideColumn,
    saveData,
    setSaveData,
    getID,
    data,
  }

  const styles = {
    placeholder: (base) => ({
      ...base,
      fontSize: "13px",
      fontWeight: "normal"
    }),
    control: (styles) => ({
      ...styles,
      borderRadius: "5px",
      boxShadow: "none",
      height: "2.8rem",
      "&:hover": {
        border: "2px solid #dadada",
      },
      border: "2px solid #dadada",
      fontSize: "13px",
      width: "185px",
    }),
  }

  const paddStyle = {
    padding: !hasMore ? "0px" : "0 0 10px 0",
  };
  const loaderContainer = {
    height: filteredOrders?.length < 1 ? `60vh` : "auto",
  };

  const metroData = metros?.map(item => item.value)
  const statusData = statusFilter?.map(item => item.value)
  const hubData = hubs?.map(item => item.id)
  const strStatus = status?.map((elem) => elem?.value)
  const shiftTypeData = shiftTypeState?.map((elem) => elem?.value)
  const firstStatusRow = ["assigned", "sort_assigned", "interestedDriver_delivery", "created", "interestedDriver_sort"]
  const seconStatusdRow = ["contractor_assigned", "pickup_assigned", "interestedDriver_pickup", "rejected", "cancelled"]
  const thridStatusdRow = ["delivery_started", "complete", "approved", "paid"]

  const queryObj = useMemo(
    () => (
      {
        fromDate: isApply ? fromDate : (saveData ? fromDate : dateStart),
        toDate: isApply ? toDate : (saveData ? toDate : dateEnd),
        metro: metro ? (saveData ? metroData : [metro]) : metroData,
        hub: hub ? (saveData ? hubData : [hub?.id]) : hubData,
        status: statusData.length ? statusData : (saveData ? statusData : strStatus),
        shiftType: shiftTypeData,
        search,
        isTotal,
        isShiftRejectReason,
        isArriveHub,
        routeCode: selectedRouteMethod?.value
      }),
    [fromDate, toDate, metros, hubs, statusFilter, status, search, isTotal, strStatus, dateStart, dateEnd, hub, metro, isShiftRejectReason, isArriveHub, selectedRouteMethod, shiftTypeState]
  );
  const refreshData = () => actions.refetch.setIsDataRefetch(true);
  const [cancelShiftReason, setCancelShiftReason] = useState("");

  const handleFirstQuery = () => {
    setFilteredOrders([]);
    setPage(1);
    setHasMore(true);
  };

  const checkIsMore = (data) =>
    data?.length < limit ? setHasMore(false) : setHasMore(true);

  useEffect(() => {
    selectedOrders.length > 0
      ? setPayedOrdersChecked(true)
      : setPayedOrdersChecked(false);
  }, [selectedOrders]);

  useEffect(() => {
    actions.metro.selectMetro("");
    actions.hub.selectHub({ name: undefined, id: "" });
    actions.refetch.setIsDataRefetch(true);
  }, [pathname]);

  useEffect(() => {
    let timer = false;
    if (!modalOrderOpen) timer = setTimeout(() => setActiveItem(false), 1500);
    return () => clearTimeout(timer);
  }, [modalOrderOpen]);

  useEffect(() => {
    if (!isRefetch) return;
    handleFirstQuery();
    getShiftData({ ...queryObj, page: 1, limit });
  }, [isRefetch]);

  useEffect(() => {
    if (!isRefetch) return;
    (async () => {
      const response = await getOrdersCount(
        //   {
        //   fromDate: isApply ? fromDate : dateStart,
        //   toDate: isApply ? toDate : dateEnd,
        //   metro: metro ? [metro] : metroData,
        //   hub: hub ? [hub?.id] : hubData,
        //   status: statusData.length ? statusData : strStatus,
        //   search,
        //   isArriveHub,
        //   routeCode: selectedRouteMethod?.value
        // },
        queryObj
      );
      const { shiftCountData } = response || {};
      setStatusArr(shiftCountData);

    })();
  }, [isRefetch]);


  useEffect(() => {
    setInterestedDriverModalData({
      fromDate: isApply ? fromDate : dateStart,
      toDate: isApply ? toDate : dateEnd,
      metro: metro ? [metro] : metroData,
      hub: hub ? [hub?.id] : hubData,
      status: statusData.length ? statusData : strStatus,
    })
  }, [isRefetch])

  const getShiftData = async (query) => {
    setDisable(true);
    const response = await getOrdersList(query);
    if (response) {
      setSelectedOrders([])
    }
    setTotal([])
    const { shiftsData } = response || {};
    setTotal(shiftsData.total)

    setFilteredOrders((filteredOrders) => [
      ...new Set([...filteredOrders, ...shiftsData?.result]),
    ]);

    setDisable(false);
    checkIsMore(shiftsData?.result);
    setPage((page) => page + 1);
    setIsLoading(false);
    actions.refetch.setIsDataRefetch(false);
  };

  const openDriverModal = useCallback((e) => {
    e.preventDefault();
    actions.modal.openDriver(e.currentTarget.getAttribute("data-driver-id"));
  }, []);

  const getActiveItem = (id) => setActiveItem(id);


  const onSaveDataChange = async (saveData) => {
    const getsingleData = getsavedData?.filter((item) => item?.filtername === saveData?.value)
    const filterData = getsingleData && getsingleData[0]?.filterdata

    setGetID(getsingleData && getsingleData[0]?._id)
    const metroData = filterData?.metro?.map((item) => { return { value: item, label: item, } })
    const status = filterData?.status?.map((item) => { return { value: item, label: item, } })
    const hub = filterData?.hub?.map((item) => { return { value: item.name, label: item.name, id: item?._id } })
    const columnData = filterData?.showcolumn?.filter((item) => data.includes(item))
    const hidedata = filterData?.hidecolumn?.filter((item) => data.includes(item))
    const shiftLog = filterData?.shiftLog
    const shiftRejectReason = filterData?.shiftRejectReason

    const isDriverArrivedLabels = {
      label: filterData?.isDriverArrived === "1" ? "Arrival on time" : filterData?.isDriverArrived === "2" ? "Arrival late" : null,
      value: filterData?.isDriverArrived || null
    };
    const isDriverArrived = filterData?.isDriverArrived || "";

    // setMetros(metroData || [])
    actions.metro.selectMetros(metroData || [])
    setStatusFilter(status || [])
    setFromDate(filterData?.shifFromDate || new Date())
    setToDate(filterData?.shifToDate || new Date())
    setSaveData(saveData)
    setHubs(hub || [])

    saveData && shiftRejectReason ? setIsShiftRejectReason(shiftRejectReason) : setIsShiftRejectReason(false)
    saveData && shiftLog ? setRejectStatus(shiftLog) : setRejectStatus(false)
    saveData && isDriverArrived ? setIsArriveHub(isDriverArrived) : setIsArriveHub(isDriverArrived)
    setIsRejectShift(shiftLog)
    setIsRejectReason(shiftRejectReason || false)
    setIsArriveToggle(filterData?.isDriverArrived === null ? "" : filterData?.isDriverArrived === undefined ? "" : isDriverArrivedLabels)

    setShowCancel([])
    setColumnData(columnData || data)
    !openDrawer && setShowColumn(columnData || data)
    setHideColumn(hidedata ? hidedata?.length === 0 ? defaultHideShiftColumn : hidedata : defaultHideShiftColumn)
    setState([
      {
        startDate: filterData?.shifFromDate ? new Date(filterData?.shifFromDate) : new Date(),
        endDate: filterData?.shifToDate ? new Date(filterData?.shifToDate) : new Date(),
        key: "selection",
      },
    ]);
    setPrevDate([
      {
        startDate: filterData?.shifFromDate ? new Date(filterData?.shifFromDate) : new Date(),
        endDate: filterData?.shifToDate ? new Date(filterData?.shifToDate) : new Date(),
        key: "selection",
      },
    ]);
    if (!isApply) {
      actions.metro.selectMetro("");
      setHub({ name: "", id: "" })
      !search && setSearchQuery("")
      !search && setSearch("")
      setStatus([])
      setIsShiftRejectReason(shiftRejectReason || false)
      setIsRejectShift(shiftLog)
      setDateStart(new Date())
      setDateEnd(new Date())
      setFromDate(filterData?.shifFromDate || new Date())
      setToDate(filterData?.shifToDate || new Date())
      setPrevFilterDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ])
      setDate([
        {
          startDate: filterData?.shifFromDate || new Date(),
          endDate: filterData?.shifToDate || new Date(),
          key: "selection",
        },
      ])
    }

  }

  const hideShowColumn = useCallback(
    (name) => {
      const result = showColumn?.some((item) => (item === name))
      return result
    },
    [showColumn]
  );

  /** `
   *     Table columns data...`
   **/
  const columns = [
    {
      name: "checkbox",
      sortable: false,
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <input
            type="checkbox"
            onClick={(event) =>
              handleSelectAllClick(
                event,
                filteredOrders,
                selectedOrders,
                setSelectedOrders,
                exceptionsPaydata,
                setExceptionsPaydata,
                exceptionsApprovedata,
                setExceptionsApprovedata
              )
            }
            checked={
              selectedOrders.length > 0 &&
              selectedOrders[0].status === "complete"
            }
            onChange={() => { }}
            className="mainCheckbox"
            disabled={
              selectedOrders.length > 0 &&
              selectedOrders[0].status !== "complete"
            }
          />
        </div>
      ),
      className: "table__td_has-checkbox",
      renderer: (item) => {
        const isSelected = (name) => selectedOrders.indexOf(name) !== -1;
        let isItemSelected = isSelected(item);

        return (
          <div className="orders-input-checkbox">
            {item?.status === "complete" && (role && role !== "metro_manager") &&
              +item?.price > 0 && (
                <input
                  type="checkbox"
                  onClick={(event) =>
                    handleOrderClick(
                      event,
                      item,
                      selectedOrders,
                      setSelectedOrders,
                      exceptionsPaydata,
                      setExceptionsPaydata,
                      exceptionsApprovedata,
                      setExceptionsApprovedata
                    )
                  }
                  checked={isItemSelected}
                  onChange={() => { }}
                  disabled={
                    selectedOrders.length > 0 &&
                    selectedOrders[0].status !== "complete"
                  }
                />
              )}
          </div>
        );
      },
    },
    hideShowColumn("Metro") ? {
      name: "metro",
      label: "Metro",
      maxWidth: "120px",
      sortable: true,
    } : { label: "HIDE" },
    hideShowColumn("Route Code") ? {
      name: "routeCode",
      label: "Route Code",
      maxWidth: "90px",
      sortable: true,
    } : { label: "HIDE" },
    hideShowColumn("Shift Type") ? {
      name: "shiftType",
      label: "Shift Type",
      maxWidth: "90px",
      sortable: true,
    } : { label: "HIDE" },
    hideShowColumn("Route Date") ? {
      name: "dayDeliveryStart",
      className: "table__td_nowrap w-5",
      label: "Route Date",
      maxWidth: "90px",
      sortable: true,
      renderer: (item) =>
        item.dayDeliveryStart &&
        moment(item.dayDeliveryStart).utc().format("MM-DD-YYYY"),
    } : { label: "HIDE" },
    hideShowColumn("Starting Delivery Station") ? {
      name: "from-name",
      label: "Starting Delivery Station",
      className: "w-6",
      sortable: true,
      renderer: (item) => item?.from?.name,
    } : { label: "HIDE" },
    hideShowColumn("Start Time") ? {
      name: "startTime",
      className: "table__td_centered align-center",
      label: "Start Time",
      type: "number",
      maxWidth: "70px",
      sortable: true,
      renderer: ({ startTime }) => (
        <p style={{ width: "4rem", position: "relative", margin: "auto" }}>
          {getRoute(startTime)}
        </p>
      ),
    } : { label: "HIDE" },
    hideShowColumn("Estimated Shift Time(in hours)") ? {
      name: "estimatedShiftTimeFrom",
      className: "table__td_centered table__td_min-horiz-padding align-center",
      label: "Estimated Shift Time",
      sublabel: "",
      sortable: true,
      renderer: ({ estimatedShiftTimeFrom, estimatedShiftTimeTo }) => {
        return handleEstimatedShiftTime(
          estimatedShiftTimeFrom,
          estimatedShiftTimeTo
        );
      },
    } : { label: "HIDE" },
    hideShowColumn("Estimated Shift Pay") ? {
      name: "estimatedShiftPayFrom",
      className: "table__td_centered table__td_min-horiz-padding align-center",
      label: "Estimated Shift Pay",
      sublabel: "",
      sortable: true,
      renderer: ({ estimatedShiftPayFrom, estimatedShiftPayTo }) => {
        return handleEstimatedShiftPay(
          estimatedShiftPayFrom,
          estimatedShiftPayTo
        );
      },
    } : { label: "HIDE" },
    hideShowColumn("Payout") ? {
      name: "price",
      label: "Payout",
      sortable: true,
      renderer: ({ price }) => (price ? `$${handleCurrency(price)}` : ""),
    } : { label: "HIDE" },
    hideShowColumn("Driver Name") ? {
      name: "driver-firstName",
      label: "Driver Name",
      maxWidth: "140px",
      className: "align-center w-8",
      sortable: true,
      renderer: (item) =>
        item?.driver?.username && (
          <div className="align-drivername">

            {item?.isDriverArrived === "1" ? (
              <div className="arrive-driver"></div>
            ) : item?.isDriverArrived === "2" ? (
              <div className="arrive-driver not-arrive-driver"  ></div>
            ) : null}

            <div>
              <span data-driver-id={item?.driver?._id} onClick={openDriverModal}>
                {`${item?.driver?.firstName || ""} ${item?.driver?.lastName || ""}`}
              </span>
              {item?.driverArrivedAt && <span className="driver-arrive-at">
                {moment(item?.driverArrivedAt).utc().format("MM-DD-YYYY hh:mm A")}
              </span>}
            </div>
          </div>
        ),
    } : { label: "HIDE" },
    hideShowColumn("Driver Shift Count") ? {
      name: "driver-orderCount",
      className: "table__td_centered align-center",
      label: "Driver Shift",
      maxWidth: "50px",
      type: "number",
      sortable: true,
      renderer: (item) => item?.driver?.orderCount
    } : { label: "HIDE" },
    hideShowColumn("Driver Phone Number") ? {
      name: "driver-phone",
      label: "Driver Phone Number",
      className: "align-center w-7",
      sortable: true,
      renderer: ({ driver }) =>
        driver?.phone && (
          <a
            href={`tel: {item.driver.phone}`}
            className="table_orders__driver-phone"
          >
            {driver.phone}
          </a>
        ),
    } : { label: "HIDE" },
    // hideShowColumn("Company") ? {
    //   className: "align-center w-5",
    //   name: "company", label: "Company", sortable: true, maxWidth: "200px",
    //   renderer: (item) =>
    //     `${item?.driver?.company?.companyCode ? item?.driver?.company.companyCode : ""} 
    //     ${item?.driver?.company?.companyCode && item?.driver?.company?.companyName ? "-" : ""} 
    //     ${item?.driver?.company ? item?.driver?.company.companyName : ""}`,
    // } : { label: "HIDE" },
    hideShowColumn("Zones/Notes") ? { name: "note", label: "Zones/Notes", sortable: true, maxWidth: "125px" } : { label: "HIDE" },
    hideShowColumn("Internal notes") ? {
      name: "internalNote",
      label: "Internal notes",
      sortable: true,
      maxWidth: "125px",
    } : { label: "HIDE" },
    // hideShowColumn("Confirm") ? {
    //   name: "isConfirmed",
    //   label: "Confirm",
    //   sortable: true,
    //   className: "table__td_have-btn align-center",
    //   renderer: ({ isConfirmed, status, _id }) => {
    //     return (
    //       <>
    //         {isConfirmed ? (
    //           <span style={handleStatusesBadge("confirmed")}>Confirmed</span>
    //         ) : (
    //           <>
    //             {status === "assigned" ? (
    //               <button
    //                 className="btn btn_block customized-details-btn"
    //                 type="button"
    //                 disabled={removeReq.isLoading}
    //                 onClick={() => handleConfirm(_id)}
    //               >
    //                 Confirm
    //               </button>
    //             ) : (
    //               "-"
    //             )}
    //           </>
    //         )}
    //       </>
    //     );
    //   },
    // } : { label: "HIDE" },
    hideShowColumn("Status") ? {
      name: "status",
      className: "table__td_centered align-center w-5",
      label: "Status",
      sortable: true,
      renderer: (item) => (
        <React.Fragment>
          <div className="status-in-table">
            <div className="status-in-table__text" style={{ margin: '5px 0' }}>
              <span style={handleStatusesBadge(item.status)}>
                {/* {removeDash(
                  item?.status === "approved"
                    ? "Waiting for approval"
                    : 
                    convertToCapitalize(item?.status)
                )} */}
                {convertToCapitalize(item?.status)}
              </span>
              {
                (statusList.includes(item?.status) && item?.price) ?
                  (<>
                    <br />
                    <span style={handleStatusesBadge(item.status)}>
                      {handlePaymentStatus(item?.status, item?.price, item?.isRequestedPayments, item?.isWeeklyPayments)}
                    </span>
                  </>) : null
              }
            </div>
          </div>
        </React.Fragment>
      ),
    } : { label: "HIDE" },
    {
      name: "details",
      className: "table__td_have-btn align-center",
      renderer: (item) => {
        let path = loc.pathname;
        return (
          <button
            className="btn btn_block btn_accent customized-details-btn"
            type="button"
            onClick={() => {
              actions.modal.openOrder({ item, path });

              getActiveItem(item._id);

            }}
          >
            Details <IoChevronForward className="btn__icon btn__icon_right" />
          </button>
        );
      },
    },
    rejectStatus ? {
      name: "rejectstatus",
      className: "table__td_centered align-center",
      renderer: (item) => (
        <React.Fragment>
          <button
            className="btn btn_block btn_accent customized-details-btn w-auto"
            onClick={() => actions.modal.openOrderLogs(item._id)}
            type="button"
          >
            Logs
          </button>
        </React.Fragment>
      ),
    } : { label: "HIDE" },
    isShiftRejectReason ? {
      name: "rejectdriver",
      label: "Reject Driver",
      sortable: true,
      className: "table__td_centered align-center",
      renderer: (item) => (
        <React.Fragment>
          <div>{item?.rejectedDriver && item?.rejectedDriver?.length !== 0 ? item?.rejectedDriver[0].firstName + " " + item?.rejectedDriver[0].lastName : '-'}</div>
        </React.Fragment>
      ),
    } : { label: "HIDE" },
    isShiftRejectReason ? {
      name: "rejectreason",
      label: "Reject Reason",
      sortable: true,
      className: "table__td_centered align-center",
      renderer: (item) => (
        <React.Fragment>
          <div>{item?.rejectedReason ? item?.rejectedReason : '-'}</div>
        </React.Fragment>
      ),
    } : { label: "HIDE" },
    hideShowColumn("Cancel Status") ? showCancel.length === 0 || showCancel.includes('cancelled') ? {
      name: "cancelType",
      className: "table__td_centered align-center w-7",
      label: "Cancel Status",
      sortable: true,
      renderer: (item) => {
        return (
          <div>
            {item?.cancelType ? cancelType(item?.cancelType) : "-"}
          </div>
        );
      },
    } : { label: "HIDE" } : { label: "HIDE" },
    {
      name: "checkbox",
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <input
            type="checkbox"
            onClick={(event) =>
              handleSelectAll(
                event,
                selectedOrders,
                filteredOrders,
                setSelectedOrders
              )
            }
            checked={
              selectedOrders?.length > 0 &&
              selectedOrders[0].status !== "complete"
            }
            onChange={() => { }}
            className="mainCheckbox"
            disabled={
              selectedOrders.length > 0 &&
              selectedOrders[0].status === "complete"
            }
          />
        </div>
      ),
      className: "table__td_has-checkbox",
      renderer: (item) => {
        const isSelected = (name) => selectedOrders.indexOf(name) !== -1;
        let isItemSelected = isSelected(item);
        return (
          <div className="orders-input-checkbox">
            {["created", "assigned"].includes(item?.status) && (
              <input
                type="checkbox"
                onClick={(event) =>
                  handleOrderClick(
                    event,
                    item,
                    selectedOrders,
                    setSelectedOrders,
                    exceptionsPaydata,
                    setExceptionsPaydata,
                    exceptionsApprovedata,
                    setExceptionsApprovedata
                  )
                }
                checked={isItemSelected}
                onChange={() => { }}
                disabled={
                  selectedOrders.length > 0 &&
                  selectedOrders[0].status === "complete"
                }
              />
            )}
          </div>
        );
      },
    },
    role && ["root", "admin", "metro_manager"].includes(role)
      ? {
        name: "delete",
        className: "table__td_have-btn align-center",
        renderer: ({ status, _id }) =>
          status &&
          ["created", "assigned"].includes(status) && (
            <button
              className={cn("btn btn_cancel customized-details-btn")}
              type="button"
              disabled={removeReq.isLoading}
              onClick={() => {
                setDeleteShiftTemplate([true, [_id]]);
                setCancelShiftReason("");
                setIsRejectingShift(true)
                setReasonForScore("excess_drivers")
              }}
            >
              Cancel
              <MdCancel className="btn__icon btn__icon_right icon_danger" />
            </button>
          ),
      }
      : role ? { label: "ROOT_AND_ADMIN_CAN_SEE" } : { label: "HIDE" },
  ];
  /** `
   *     Fetching data with SearchQuery...`
   **/
  const handleOnChange = (e) => {
    setSearchQuery(e.target.value);
    e.target.value === "" && handleFilterBySearchQuery("CLEAR");
  };
  const handleFilterBySearchQuery = async (isClear) => {
    setTotal([])
    const search = isClear && isClear === "CLEAR" ? "" : searchQuery;
    setSearch(search);
    handleFirstQuery();
    actions.refetch.setIsDataRefetch(true);
  };


  const onResetClick = async () => {
    setTotal([])
    setShowDateRangePicker(false);
    setFromDate(new Date());
    setToDate(new Date());
    setShowDateRangePicker(false);
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setPrevDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  const onCancelClick = () => {
    setShowDateRangePicker(false);
    setState(prevDate);
  };

  const handleTimeRangeSelection = async () => {
    setTotal([])
    const { startDate, endDate } = state[0] || {};
    const { dt: strtDt } = new DateCreater(startDate);
    const { dt: endDt } = new DateCreater(endDate);
    setFromDate(strtDt);
    setToDate(endDt);
    setShowDateRangePicker(false);
    setPrevDate(state);
  };

  const handleCancellingShift = async () => {
    const isMultipleCancel = deleteShiftTemplate[1]?.length > 1;
    const addMultipleIds = () => selectedOrders?.map((shift) => shift?._id);
    const ids = isMultipleCancel ? addMultipleIds() : deleteShiftTemplate[1];

    const result = await remove({
      ids,
      reason: cancelShiftReason,
      cancelType: reasonForScore
    });
    const msg = isMultipleCancel ? CANCELLED_MULTIPLE : CANCELLED_SINGLE;
    if (!result?.error && result?.data?.status === 200) sendSuccessMessage(msg);
    else checkForError(result);

    refreshData();
    setDeleteShiftTemplate([false, []]);
    setSelectedOrders([]);
    setExceptionsApprovedata([]);
    setExceptionsPaydata([])
  };

  const fetchMoreShifts = async (e) => {
    const isFetchMore = !isRefetch && page > 1;
    const query = {
      ...queryObj,
      page,
      limit,
    };
    isFetchMore && (await getShiftData(query));
  };

  /**
   *   `CSV downloading function without pagination`
   **/

  const downloadReport = useCallback(async () => {
    const { shiftsData: data } = await getOrdersList({ ...queryObj, isExport: true });
    if (checkArrForMapping(data?.result)) return data?.result;
    return;
  }, [queryObj]);

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [csvData]);

  const exportData = async (value) => {
    setAnchorEl(null)
    setIsLoadingCsv(true);
    const download = await downloadReport()
    setIsLoadingCsv(false);
    const filename = fromDate && toDate ? `Shifts_from_${fromDate}_to_${toDate}` : "Shifts";
    const data = shiftsData(download)

    if (value === "csv") {
      const obj = dataExport(data, filename, headers)
      setCsvData(obj);
    } else if (value === "xlsx") {
      xlsxDataExport(data, filename)
    }
  }

  const handleKeyDown = ({ key }) =>
    key === "Enter" && handleFilterBySearchQuery();

  const handleStatusBadge1 = useMemo(() => {
    return arrOfStatusesCount?.map(({ status, count }) => {
      return (
        count > 0 &&
        firstStatusRow.includes(status) && (
          <StatusBadge
            InterestedDriverModalDatas={InterestedDriverModalData}
            key={status}
            count={count}
            title={rewordingOrderStatus(status)}
          />
        )
      );
    });
  }, [arrOfStatusesCount]);

  const handleStatusBadge2 = useMemo(() => {
    return arrOfStatusesCount?.map(({ status, count }) => {
      return (
        count > 0 &&
        seconStatusdRow.includes(status) && (
          <StatusBadge
            InterestedDriverModalDatas={InterestedDriverModalData}
            key={status}
            count={count}
            title={rewordingOrderStatus(status)}
          />
        )
      );
    });
  }, [arrOfStatusesCount]);

  const handleStatusBadge3 = useMemo(() => {
    return arrOfStatusesCount?.map(({ status, count }) => {
      return (
        count > 0 &&
        thridStatusdRow.includes(status) && (
          <StatusBadge
            InterestedDriverModalDatas={InterestedDriverModalData}
            key={status}
            count={count}
            title={rewordingOrderStatus(status)}
          />
        )
      );
    });
  }, [arrOfStatusesCount]);

  const handleCloseDrawer = () => {
    if (isApply) {
      setOpenDrawer(false)
      // setMetros(prevMetros)
      setHubs(prevHubs)
      setStatusFilter(prevStatusFilter)

    } else {
      setOpenDrawer(false)
      handleState()
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ])
    }
  }

  const handleCustomRange = () => {
    !disable && setShowDateRangePicker(!showDateRangePicker);
    setState(prevDate);
  }

  const handleApplyFilter = () => {
    setOpenDrawer(false)
    setIsApply(true)
    setPrevHubs(hubs)
    setPrevStatusFilter(statusFilter)
    setRejectStatus(isRejectShift)
    setIsShiftRejectReason(isRejectReason)
    setIsArriveHub(isArriveToggle?.value || "")
    setShowCancel([])
    actions.metro.selectMetro("");
    setHub({ name: "", id: "" })
    !search && setSearchQuery("")
    !search && setSearch("")
    setStatus([])
    setShowColumn(columndata)
    setDateStart(new Date())
    setDateEnd(new Date())
    setPrevFilterDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ])
    actions.refetch.setIsDataRefetch(true);
  }

  const handleState = () => {
    // setMetros([])
    actions.metro.selectMetros([])
    setHubs([])
    setStatusFilter([])
    setFromDate(new Date())
    setToDate(new Date())
    setShowColumn(hideShiftColumn)
    setHideColumn(defaultHideShiftColumn)
    setRejectStatus(false)
    setIsRejectShift(false)
    setIsRejectReason(false)
    setIsShiftRejectReason(false)
    setIsArriveHub("")
    setIsArriveToggle("")
    setPrevDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ])
    setSaveData("")
  }

  const handleReset = () => {
    setOpenDrawer(false)
    setIsApply(false)
    handleState()
    setState(currentDtArr)
    setFilteredOrders([]);
    setShowColumn(hideShiftColumn)
    setHideColumn(defaultHideShiftColumn)
    setRejectStatus(false)
    setIsRejectShift(false)
    setIsRejectReason(false)
    setIsShiftRejectReason(false)
    setIsArriveHub("")
    setIsArriveToggle("")
    actions.refetch.setIsDataRefetch(true);
    setDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ])
  }

  const handleClearAdvanceFilter = () => {
    // setMetros([])
    actions.metro.selectMetros([])
    setHubs([])
    setColumnData(data)
    setShowColumn(data)
    setHideColumn(defaultHideShiftColumn)
    setRejectStatus(false)
    setIsRejectShift(false)
    setIsRejectReason(false)
    setIsShiftRejectReason(false)
    setIsArriveHub("")
    setIsArriveToggle("")
    setStatusFilter([])
    setFromDate(new Date())
    setToDate(new Date())
    setPrevDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ])
    setIsApply(false)
    setState(currentDtArr)
    setSaveData("")
  }

  const handleStatusFilter = async (e) => {
    const dataStatus = []
    for (var i of status) {
      dataStatus.push(i.value)
    }
    setShowCancel(dataStatus)
    setTotal([])
    handleClearAdvanceFilter()
    actions.refetch.setIsDataRefetch(true);
  };

  const handleShiftTypeFilter = () => {
    setTotal([])
    handleClearAdvanceFilter()
    actions.refetch.setIsDataRefetch(true);
  };

  const handleHubFilter = async (hubObj) => {
    setTotal([])
    const { name, _id: id } = hubObj || {};
    const obj = { name: name ? name : "", id: id ? id : "" };
    actions.hub.selectHub(obj);
    setHub(obj)
    handleClearAdvanceFilter()
    actions.refetch.setIsDataRefetch(true);
  };

  const handleMetroFilter = async (newMetro) => {
    setTotal([])
    actions.metro.selectMetro(newMetro);
    handleClearAdvanceFilter()
    actions.refetch.setIsDataRefetch(true);
  };

  useEffect(() => {
    actions.refetch.setIsDataRefetch(true);
  }, [selectedRouteMethod])

  const handleResetClick = async () => {
    const currDt = new Date();
    const { dt: strtDt } = new DateCreater(new Date());
    const { dt: endDt } = new DateCreater(new Date());
    const data = [
      {
        startDate: currDt,
        endDate: currDt,
        key: "selection",
      },
    ]
    setTotal([])
    setShowDate(false);
    setDateStart(strtDt);
    setDateEnd(endDt);
    setDate(data);
    setIsLoading(true);
    setPrevFilterDate(data);
    handleFirstQuery();
    handleClearAdvanceFilter()
    actions.refetch.setIsDataRefetch(true);
  }

  const handleTimeSelection = async () => {
    setTotal([])
    const { startDate, endDate } = date[0] || {};
    const { dt: strtDt } = new DateCreater(startDate);
    const { dt: endDt } = new DateCreater(endDate);
    setDateStart(strtDt);
    setDateEnd(endDt);
    setShowDate(false);
    setPrevFilterDate(date);
    handleFirstQuery();
    handleClearAdvanceFilter()
    actions.refetch.setIsDataRefetch(true);

  }

  const handleApprovePayments = async () => {
    for (let order of exceptionsApprovedata) {
      await changeStatusForApproveOrderPayments({ _id: order?._id, });
      setShowConfirmApprove(false);
      setSelectedOrders([]);
      setExceptionsPaydata([])
      setExceptionsApprovedata([])
      setIsLoading(true);
      const response = await getOrdersList({ ...queryObj, page: 1, limit });
      if (response) {
        setSelectedOrders([])
      }
      setTotal([])
      const { shiftsData } = response || {};
      setTotal(shiftsData.total)
      setFilteredOrders(shiftsData?.result)
      setDisable(false);
      checkIsMore(shiftsData?.result);
      setPage((page) => page + 1);
      setIsLoading(false);
      actions.refetch.setIsDataRefetch(false);
    }
  }

  return (
    <React.Fragment>

      {showConfirmApprove && (
        <ConfirmDialogModal
          bodyText={
            isApproveOrderLoading ? (
              <CircularProgress />
            ) : (
              "Approve all Payments?"
            )
          }
          isOpen
          onClose={() => {
            if (
              (!isApproveOrderLoading)
            ) {
              setShowConfirmApprove(false);
              setSelectedOrders([]);
              setExceptionsApprovedata([])
              setExceptionsPaydata([])
            }

          }}
          confirmAction={() => {
            handleApprovePayments();
          }}
        />
      )}

      {deleteShiftTemplate[0] && (
        <ConfirmDialogModal
          title="Write a reason for cancelling shift"
          bodyText={
            <RejectShift
              payExceptionNote={cancelShiftReason}
              setPayExceptionNote={(e) => setCancelShiftReason(e.target.value)}
              isCancellingShift
              setReasonForScore={(value) => setReasonForScore(value)}
              reasonForScore={reasonForScore}
            />
          }
          isOpen
          onClose={() => {
            setDeleteShiftTemplate([false, ""])
          }}
          confirmAction={handleCancellingShift}
          modalText="CANCEL__SHIFT"
          isLoading={removeReq.isLoading}
          isRejectingShift={isRejectingShift}
          payExceptionNote={cancelShiftReason}
        />
      )}
      {showMassPaymentsModal && (
        <MassPaymentsModal
          isOpen
          onClose={() => {
            setShowMassPaymentsModal(false);
            setSelectedOrders([]);
            setExceptionsPaydata([]);
            setExceptionsApprovedata([])
          }}
          orders={exceptionsPaydata}
        />
      )}
      <CardLayout >
        <div className="shift_main_div" style={{ zIndex: 99 }}>
          <HeaderTextLayout
            hideBorder={true}
            headerText="Shifts"
            className="col-md-2 main__driver shift_header"

            data={
              filteredOrders && filteredOrders.length > 0
                ? `(${filteredOrders.length.toString().padStart(2, '0')} / `
                : ""
            }
            dataLength={
              checkArrForMapping(filteredOrders) ? filteredOrders.length : "0"
            }
            totalLength={
              filteredOrders?.length && total && total > 0
                ? `${total.toString().padStart(2, '0')})`
                : ""
            }
            showCustome={true}>
            <div className="" style={{ display: "flex", justifyContent: "space-between", width: "94%" }} >
              <div style={{ display: "flex", flexDirection: "column", columnGap: "3px", rowGap: "5px" }}>
                <div>{handleStatusBadge1}</div>
                <div>{handleStatusBadge2}</div>
                <div>{handleStatusBadge3}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", alignItems: "center" }}>

                <div style={{ display: "flex", alignItems: "center", gap: "5px", flexWrap: "wrap", justifyContent: "end", width: "100%" }}>
                  {payedOrdersChecked && selectedOrders[0]?.status === "complete" ? (
                    (role && role !== "metro_manager") && (
                      <>
                        {exceptionsApprovedata.length > 0 && (
                          <button
                            style={{ fontSize: "13px", minHeight: "40px" }}
                            onClick={() => {
                              setShowConfirmApprove(true);
                            }}
                            className="btn btn_accent"
                          >
                            Approve ({exceptionsApprovedata.length})
                          </button>
                        )}
                        {exceptionsPaydata.length > 0 && role === 'root' && (
                          <button
                            style={{ fontSize: "13px", minHeight: "40px" }}
                            onClick={() => setShowMassPaymentsModal(true)}
                            className="btn btn_accent"
                          >
                            <MdPayment className="btn__icon" />
                            Pay ({exceptionsPaydata.length})
                          </button>
                        )}
                      </>
                    )
                  ) : (
                    payedOrdersChecked && (
                      <button
                        onClick={() => {
                          setDeleteShiftTemplate([true, selectedOrders]);
                          setCancelShiftReason("");
                          setReasonForScore("excess_drivers");
                        }}
                        disabled={!selectedOrders.length}
                        className="btn btn_accent"
                        style={{ fontSize: "13px", minHeight: "40px" }} >
                        <ClearAll className="btn__icon" />
                        Cancel ({selectedOrders.length})
                      </button>
                    )
                  )}

                  <div className="d-flex align-items-center metro_div">

                    <MetroSelect
                      width={185}
                      value={metro || ""}
                      onChange={(event, newValue) => handleMetroFilter(newValue)}
                      className="auto__complete"
                      isLoading={disable}
                      showLabel={true}
                    />
                  </div>
                  <div className="filter__ctrls-holder filter__input-holder d-flex justify-content-end search__container" >
                    <div className="searchbar__container" style={{ width: "210px" }} >
                      <input
                        style={{ fontSize: "13px" }}
                        className="form__input customized__input search_input"
                        value={searchQuery}
                        onChange={handleOnChange}
                        onKeyDown={handleKeyDown}
                        disabled={disable}
                        placeholder="Quick Search"
                      />
                      {searchQuery?.length > 0 && (
                        <ClearIcon
                          className="clear__btn filter__search-icon"
                          onClick={() => {
                            setSearchQuery("");
                            handleFilterBySearchQuery("CLEAR");
                          }}
                          style={{
                            right: "45px",
                            top: "12px",
                          }}
                        />
                      )}
                      <button
                        disabled={searchQuery?.length < 1}
                        className="search__button"
                        onClick={handleFilterBySearchQuery}
                      >
                        <IoSearchSharp />
                      </button>
                    </div>
                  </div>

                  <div className="shift_select_div">
                    {saveData && <div className="select_label">
                      <span>{"Saved Filter"}</span>
                    </div>}
                    <ReactSelect
                      options={getsavedData?.map((item) => {
                        return {
                          value: item?.filtername,
                          label: item?.filtername,
                        };
                      })}
                      isClearable={true}
                      onChange={(item) => {
                        onSaveDataChange(item)
                        actions.refetch.setIsDataRefetch(true);
                      }}
                      placeholder="Saved Filter"
                      value={saveData}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={styles}
                    />
                  </div>

                  <button style={{ height: "43px" }} className="refresh_btn filter_btn"
                    disabled={disable}
                    onClick={() => setOpenDrawer(true)}>
                    <img src={filterLogo} alt="" className="filterit" style={{ width: "25px" }} />
                  </button>

                  <div>
                    <button
                      style={{ fontSize: "14px", minHeight: "40px" }}
                      id="fade-button"
                      className="btn btn_block btn_accent btn_export_15"
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                      variant="contained"
                      disabled={isLoadingCsv || filteredOrders?.length === 0}>
                      {isLoadingCsv ? (
                        <div className="loading" style={{ color: "white" }}>
                          <span className="loading__text" style={{ color: "white" }}>
                            Exporting
                          </span>
                          ...
                        </div>
                      ) : (
                        <> <span> Export <KeyboardArrowDownIcon className="mr-0" /></span> </>
                      )}
                    </button>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        'aria-labelledby': 'fade-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClick={() => setAnchorEl(null)}
                      TransitionComponent={Fade}>
                      {exportMenu.map(({ value, name }) => (
                        <MenuItem key={name} value={name} onClick={() => exportData(value)} style={{ width: "100px" }}>
                          {name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>


                  <button
                    style={{
                      background: "white",
                      borderColor: "#e6e6e6",
                      minWidth: "0px",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    className="btn btn__accent "
                    onClick={() => actions.modal.openOrder({ state: isApply ? state : date })}>
                    <IoIosAddCircle style={{ fontSize: "30px", margin: "0px" }} />
                  </button>

                  {csvData ? (
                    <CSVLink
                      data={csvData.data}
                      headers={csvData.headers}
                      filename={csvData.filename}
                      ref={csvInstance}
                    />
                  ) : undefined}
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: "5px", flexWrap: "wrap", justifyContent: "end", width: "100%", marginTop: "9px" }}>
                  {(role === 'root' || role === 'admin') &&
                    <div>
                      <button className="btn btn_accent" style={{ fontSize: "14px" }} onClick={() => actions.modal.openBulkOrderModal({ fromDate: date[0]?.startDate, toDate: date[0]?.endDate })}>
                        Bulk Update
                      </button>
                    </div>
                  }

                  <div className="d-flex justify-content-end status_filter">
                    <div className="shift_select_div">
                      {shiftTypeState.length !== 0 && <div className="select_label">
                        <span>{"Select Shift Type"}</span>
                      </div>}
                      <MultiSelect
                        placeholder="Select Shift Type"
                        itemData={shiftTypeOption?.map((item, i) => {
                          return {
                            value: item?.value ? item?.value : "-",
                            label: `${item?.title ? item?.title : ""}`,
                          };
                        })}
                        styles={orderReactSelectStyle}
                        value={shiftTypeState}
                        onChange={(value) => {
                          if (!value?.length) {
                            setShiftTypeState(value);
                            setShowCancel([])
                            actions.refetch.setIsDataRefetch(true);
                          } else setShiftTypeState(value);
                        }}
                      />
                    </div>
                    <button
                      disabled={shiftTypeState?.length < 1}
                      className="search__button"
                      onClick={(handleShiftTypeFilter)} >
                      <DoneLogo fill='#5fb6f7' width="24px" height="24px" />
                    </button>
                  </div>

                  {/* <div className="shift_select_div">
                    {selectedRouteMethod && <div className="select_label">
                      <span>{"Select Route Method"}</span>
                    </div>}
                    <ReactSelect
                      options={shiftRouteSelectOption}
                      isClearable={true}
                      onChange={(item) => {
                        setSelectedRouteMethod(item)
                      }}
                      placeholder="Select Route Method"
                      value={selectedRouteMethod}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={styles}
                    />
                  </div> */}

                  <div ref={wrapperRef}>
                    <div
                      onClick={() => {
                        !disable && setShowDate(!showDate);
                        setDate(prevFilterDate);
                      }}
                      className="form__input"
                      style={{ fontSize: "14px", height: "45px" }}>
                      <div style={{ position: 'relative' }}>
                        <span className="label-date-picker" style={{ fontSize: "11px" }}>Route Date</span>
                      </div>
                      <span>{dateRange}</span>
                      <span className="ml-2">{isShowingDtRangePicker}</span>
                    </div>
                    {showDate && (
                      <div className="mainDateRangePicker" >
                        <DateRangePicker
                          onChange={(item) => setDate([item.selection])}
                          months={1}
                          ranges={date}
                        />
                        <div className="button-container">
                          <button
                            onClick={handleResetClick}
                            className="okButton clearButton">
                            <span>
                              <BackspaceIcon /> &nbsp;
                              <strong>RESET</strong>
                            </span>
                          </button>
                          <button
                            onClick={() => {
                              setShowDate(false);
                              setDate(prevFilterDate);
                            }}
                            className="cancelButton">
                            <span>
                              <CancelIcon
                                sx={{ width: "1.5rem", height: "1.5rem" }}
                              />
                              <strong>cancel</strong>
                            </span>
                          </button>
                          <button
                            onClick={handleTimeSelection}
                            className="okButton">
                            <span>
                              <ThumbUpAltIcon /> &nbsp;
                              <strong>Done</strong>
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    <div className="d-flex justify-content-end status_filter">
                      <div className="shift_select_div">
                        {status.length !== 0 && <div className="select_label">
                          <span>{"Select Status"}</span>
                        </div>}
                        <MultiSelect
                          placeholder="Select Status"
                          itemData={shiftReportStatus?.map((item, i) => {
                            return {
                              value: item?.value ? item?.value : "-",
                              label: `${item?.title ? item?.title : ""}`,
                            };
                          })}
                          styles={orderReactSelectStyle}
                          value={status}
                          onChange={(value) => {
                            if (!value?.length) {
                              setStatus(value);
                              setShowCancel([])
                              actions.refetch.setIsDataRefetch(true);
                            } else setStatus(value);
                          }}
                        />
                      </div>
                      <button
                        disabled={status?.length < 1}
                        className="search__button"
                        onClick={(handleStatusFilter)} >
                        <DoneLogo fill='#5fb6f7' width="24px" height="24px" />
                      </button>
                    </div>
                  </div>

                  <button className="refresh_btn" onClick={() => actions.refetch.setIsDataRefetch(true)} style={{ height: "43px" }}>
                    <Refresh className="refresh_icon" style={{ fontSize: "28px" }} />
                  </button>
                </div>
              </div>
            </div>
          </HeaderTextLayout>
        </div>

        <div className="filter" style={{ zIndex: 6 }}>
          <div className="container_extra-width filter__container">
          </div>
        </div>

        <CustomShiftDrawer
          openDrawer={openDrawer}
          handleCloseDrawer={handleCloseDrawer}
          handleCustomRange={handleCustomRange}
          onCancelClick={onCancelClick}
          handleTimeRangeSelection={handleTimeRangeSelection}
          onResetClick={onResetClick}
          handleApplyFilter={handleApplyFilter}
          handleReset={handleReset}
          obj={propsobj}
          onSaveDataChange={onSaveDataChange}
        />

        <InfiniteScroll
          className="shift_table"
          dataLength={filteredOrders?.length}
          next={fetchMoreShifts}
          hasMore={hasMore}
          style={paddStyle}
          loader={
            <div
              className="d-flex justify-content-center align-items-center"
              style={loaderContainer}
            >
              <CircularProgress className="loading-progress" />
            </div>
          }
          height={`73vh`}
          endMessage={
            !isLoading &&
            filteredOrders?.length < 1 && (
              <div
                style={{
                  height: `63vh`,
                }}
                className="d-flex justify-content-center align-items-center"
              >
                <p>
                  <b>No Result Found</b>
                </p>
              </div>
            )
          }
        >
          {isLoading ? (
            <div className="loading-holder">
              <CircularProgress className="loading-progress" />
            </div>
          ) : (
            <Table
              columns={columns}
              items={filteredOrders || []}
              className={"table_orders"}
              activeItem={activeItem}
              width={"100%"}
              maxWidth="60px"
            />
          )}
        </InfiniteScroll>
      </CardLayout>
    </React.Fragment >
  );
}

const StatusBadge = ({ count, title, InterestedDriverModalDatas }) => {
  const shiftTypeMap = {
    interested: 'DELIVERY',
    sort_interested: 'SORT',
    pickup_interested: 'PICKUP',
  };
  return (<>
    {['interested', 'sort_interested', 'pickup_interested'].includes(title) ? (
      <a onClick={() => actions.modal.openInterestedDriver({ ...InterestedDriverModalDatas, shiftType: shiftTypeMap[title] })} style={handleStatusesBadge(title)} className={`mb-2 mr-2`}>
        {removeDash(convertToCapitalize(title))}: {count}
      </a>
    ) : (
      <span style={handleStatusesBadge(title)} className={`mb-2 mr-2`}  >
        {removeDash(convertToCapitalize(title))}: {count}
      </span>
    )}
  </>)
};
